import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { DEV } from '../../constants';

const mainTheme = createTheme({
  palette: {
    primary: {
      main: DEV ? '#505050' : '#24292F',
    },
  },
});

const LayoutWrapper = (props) => (
  <ThemeProvider theme={mainTheme}>
    <CssBaseline />
    {props.children}
  </ThemeProvider>
);

export default LayoutWrapper;
