export const WINDOW = typeof window !== 'undefined';
export const DEV = process.env.NODE_ENV === 'development';
export const GRAPHQL_API_URI = DEV ? 'http://localhost:3000/graphql' : 'https://api.flycrew.com/graphql';
export const SERVER_URL = DEV ? 'http://localhost:3000' : 'https://api.flycrew.com';
export const FRONTEND_URL = DEV ? 'http://localhost:3001/' : 'https://flycrew.com/';
export const SESSION_TOKEN = 'FLYCREW_SESSION_CONTROL';
export const PER_PAGE = 25;
export const COUNTRIES_LEADER = {
  CL: 'Chile',
  MX: 'Mexico',
};

export const BANK_CHILE = [
    'Banco BBVA',
    'Banco BICE',
    'Banco Consorcio',
    'Banco Crédito e Inversiones',
    'Banco Estado',
    'Banco Falabella',
    'Banco Internacional',
    'Banco Itaú',
    'Banco Paris',
    'Banco Ripley',
    'Banco Santander',
    'Banco Santander Banefe',
    'Banco Security',
    'Banco de Chile / Edwards-Citi',
    'Banco del Desarrollo',
    'Corpbanca',
    'HSBC Bank',
    'Rabobank',
    'Scotiabank',
    'Copec Pay',
    'Coopeuch',
    'Global66',
    'La Polar',
    'Mercado Pago',
    'Prepago Los Heroes',
    'Tapp (Caja Los Andes)',
    'Tenpo',
  ];

  export const BANK_CHILE_TYPE = [
    'Cuenta Corriente',
    'Cuenta Vista',
    'Chequera Electrónica',
    'Cuenta de Ahorro',
    'Cuenta Rut',
  ];

export const CREDIT_CARD_COLORS = {
  Active: { backgroundColor: '#00e676', color: 'white' },
  Deleted: { backgroundColor: '#EC0B0B', color: 'white' },
};

export const PLAN_COLORS = {
  published: { backgroundColor: '#00e676', color: 'white' },
  hidden: { backgroundColor: '#505050', color: 'white' },
  archived: { backgroundColor: '#EC0B0B', color: 'white' },
};

export const SALE_COLORS = {
  paid: { backgroundColor: '#00e676', color: 'white' },
  refunded: { backgroundColor: '#9c27b0', color: 'white' },
  declined: { backgroundColor: '#EC0B0B', color: 'white' },
};
