import React, { useState, createContext } from 'react';
import { Helmet } from 'react-helmet';
import { DEV } from '../constants';

export const HeadContext = createContext();

export const HeadProvider = (props) => {
    const [head, setHead] = useState({
        title: 'Flycrew Admin',
    });

    return (
      <HeadContext.Provider value={{ head, setHead }}>
        <Helmet>
          <title>{head.title} {DEV ? '(dev)' : ''}</title>
        </Helmet>
        {props.children}
      </HeadContext.Provider>
    );
};
