import React, { createContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { useQuery } from '@apollo/client';
import { ME } from '../data/queries';
import Loading from '../components/Loading';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const { data, loading: loadingMe, refetch: refetchSession } = useQuery(ME);

  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data?.me) {
      setSession(data.me);
      setLoading(loadingMe);
    } else {
      setLoading(loadingMe);
    }
  }, [data, loadingMe]);

  if (loading) return <Loading />;

  return (
    <SessionContext.Provider
      value={{
        session,
        timezone: data?.me?.timezone || moment.tz.guess(),
        setSession,
        loading,
        refetchSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
