import React, { useContext, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SessionContext } from './context/SessionContext';
import ManageLayout from './components/layout/ManageLayout';

const ManageIndex = lazy(() => import('./components/manage/ManageIndex'));
const UserIndex = lazy(() => import('./components/users/UserIndex'));
const UserShow = lazy(() => import('./components/users/UserShow'));
const UserForm = lazy(() => import('./components/users/UserForm'));
const RevenueLeaderIndex = lazy(() => import('./components/revenue_leaders/RevenueLeaderIndex'));
const DiscountForm = lazy(() => import('./components/discounts/DiscountForm'));
const CurrencyForm = lazy(() => import('./components/curriencies/CurrencyForm'));
const SaleIndex = lazy(() => import('./components/sales/SaleIndex'));
const SalePending = lazy(() => import('./components/sales/SalePending'));
const SaleExtras = lazy(() => import('./components/sales/SaleExtras'));
const TransferIndex = lazy(() => import('./components/transfers/TransferIndex'));
const PaySheetsIndex = lazy(() => import('./components/paysheets/PaySheetIndex'));
const PaySheetShow = lazy(() => import('./components/paysheets/PaySheetShow'));
const ActivityIndex = lazy(() => import('./components/activities/ActivityIndex'));
const ActivityShow = lazy(() => import('./components/activities/ActivityShow'));
const ActivityForm = lazy(() => import('./components/activities/ActivityForm'));
const BookingIndex = lazy(() => import('./components/bookings/BookingIndex'));
const BookingShow = lazy(() => import('./components/bookings/BookingShow'));
const CategoryIndex = lazy(() => import('./components/categories/CategoryIndex'));
const CategoryShow = lazy(() => import('./components/categories/CategoryShow'));
const CategoryForm = lazy(() => import('./components/categories/CategoryForm'));
const CategoryBenefitIndex = lazy(() => import('./components/category_benefits/CategoryBenefitIndex'));
const CategoryBenefitShow = lazy(() => import('./components/category_benefits/CategoryBenefitShow'));
const CategoryBenefitForm = lazy(() => import('./components/category_benefits/CategoryBenefitForm'));
const BenefitIndex = lazy(() => import('./components/benefits/BenefitIndex'));
const BenefitShow = lazy(() => import('./components/benefits/BenefitShow'));
const BenefitForm = lazy(() => import('./components/benefits/BenefitForm'));
const PassCategoryIndex = lazy(() => import('./components/pass/categories/PassCategoryIndex'));
const PassCategoryShow = lazy(() => import('./components/pass/categories/PassCategoryShow'));
const PassCategoryForm = lazy(() => import('./components/pass/categories/PassCategoryForm'));
const SaleShow = lazy(() => import('./components/sales/SaleShow'));
const DiscountIndex = lazy(() => import('./components/discounts/DiscountIndex'));
const DiscountShow = lazy(() => import('./components/discounts/DiscountShow'));
const Login = lazy(() => import('./components/login/Login'));
const LoginVerification = lazy(() => import('./components/login/LoginVerification'));
const CompanyIndex = lazy(() => import('./components/companies/CompanyIndex'));
const CompanyShow = lazy(() => import('./components/companies/CompanyShow'));
const CompanyForm = lazy(() => import('./components/companies/CompanyForm'));
const PlanIndex = lazy(() => import('./components/plans/PlanIndex'));
const TranslationIndex = lazy(() => import('./components/translations/TranslationIndex'));
const SettingIndex = lazy(() => import('./components/settings/SettingIndex'));
const TranslationForm = lazy(() => import('./components/translations/TranslationForm'));

const CommunityIndex = lazy(() => import('./components/communities/CommunityIndex'));
const CommunityShow = lazy(() => import('./components/communities/CommunityShow'));

const DashboardReportIndex = lazy(() => import('./components/reports/internal_dashboard_reports/DashboardReportIndex'));
const DashboardReportForm = lazy(() => import('./components/reports/internal_dashboard_reports/DashboardReportForm'));
const DashboardSaasIndex = lazy(() => import('./components/reports/saas_reports/DashboardSaasIndex'));

const FlycrewPlanSubscriptionIndex = lazy(() => import('./components/flycrew_plan_subscriptions/FlycrewPlanSubscriptionIndex'));

const PageNotFound = lazy(() => import('./components/404/PageNotFound'));

function App() {
  const { session } = useContext(SessionContext);
  const loggedIn = session;

  return (
    <Router>
      <Suspense fallback={<></>}>
        <Routes>
          {loggedIn ? (
            <>
              <Route path="/">
                <ManageLayout>
                  <ManageIndex />
                </ManageLayout>
              </Route>
              <Route path="/users">
                <ManageLayout>
                  <UserIndex />
                </ManageLayout>
              </Route>
              <Route path="/users/:user_id">
                <ManageLayout>
                  <UserShow />
                </ManageLayout>
              </Route>
              <Route path="/users/create">
                <ManageLayout>
                  <UserForm />
                </ManageLayout>
              </Route>
              <Route path="/users/:user_id/update">
                <ManageLayout>
                  <UserForm />
                </ManageLayout>
              </Route>
              <Route path="/users/revenues">
                <ManageLayout>
                  <RevenueLeaderIndex />
                </ManageLayout>
              </Route>
              <Route path="/currencies/:currency_id/update">
                <ManageLayout>
                  <CurrencyForm />
                </ManageLayout>
              </Route>
              <Route path="/activities">
                <ManageLayout>
                  <ActivityIndex />
                </ManageLayout>
              </Route>
              <Route path="/activities/:activity_id">
                <ManageLayout>
                  <ActivityShow />
                </ManageLayout>
              </Route>
              <Route path="/activities/:activity_id/update">
                <ManageLayout>
                  <ActivityForm />
                </ManageLayout>
              </Route>
              <Route path="/activities/bookings">
                <ManageLayout>
                  <BookingIndex />
                </ManageLayout>
              </Route>
              <Route path="/activities/bookings/:booking_id">
                <ManageLayout>
                  <BookingShow />
                </ManageLayout>
              </Route>
              <Route path="/sales">
                <ManageLayout>
                  <SaleIndex />
                </ManageLayout>
              </Route>
              <Route path="/sales/pending">
                <ManageLayout>
                  <SalePending />
                </ManageLayout>
              </Route>
              <Route path="/sales/extras">
                <ManageLayout>
                  <SaleExtras />
                </ManageLayout>
              </Route>
              <Route path="/sales/transfers">
                <ManageLayout>
                  <TransferIndex />
                </ManageLayout>
              </Route>
              <Route path="/sales/paysheets">
                <ManageLayout>
                  <PaySheetsIndex />
                </ManageLayout>
              </Route>
              <Route path="/sales/paysheets/:paysheet_id">
                <ManageLayout>
                  <PaySheetShow />
                </ManageLayout>
              </Route>
              <Route path="/sales/:sale_id">
                <ManageLayout>
                  <SaleShow />
                </ManageLayout>
              </Route>
              <Route path="/plans">
                <ManageLayout>
                  <PlanIndex />
                </ManageLayout>
              </Route>
              <Route path="/categories">
                <ManageLayout>
                  <CategoryIndex />
                </ManageLayout>
              </Route>
              <Route path="/categories/:category_id">
                <ManageLayout>
                  <CategoryShow />
                </ManageLayout>
              </Route>
              <Route path="/categories/create">
                <ManageLayout>
                  <CategoryForm />
                </ManageLayout>
              </Route>
              <Route path="/categories/:category_id/update">
                <ManageLayout>
                  <CategoryForm />
                </ManageLayout>
              </Route>
              <Route path="/benefits/categories">
                <ManageLayout>
                  <CategoryBenefitIndex />
                </ManageLayout>
              </Route>
              <Route path="/benefits/categories/:category_benefit_id">
                <ManageLayout>
                  <CategoryBenefitShow />
                </ManageLayout>
              </Route>
              <Route path="/benefits/categories/create">
                <ManageLayout>
                  <CategoryBenefitForm />
                </ManageLayout>
              </Route>
              <Route path="/benefits/categories/:category_benefit_id/update">
                <ManageLayout>
                  <CategoryBenefitForm />
                </ManageLayout>
              </Route>
              <Route path="pass/categories">
                <ManageLayout>
                  <PassCategoryIndex />
                </ManageLayout>
              </Route>
              <Route path="pass/categories/:category_id">
                <ManageLayout>
                  <PassCategoryShow />
                </ManageLayout>
              </Route>
              <Route path="pass/categories/create">
                <ManageLayout>
                  <PassCategoryForm />
                </ManageLayout>
              </Route>
              <Route path="pass/categories/:category_id/update">
                <ManageLayout>
                  <PassCategoryForm />
                </ManageLayout>
              </Route>
              <Route path="benefits">
                <ManageLayout>
                  <BenefitIndex />
                </ManageLayout>
              </Route>
              <Route path="/benefits/:benefit_id">
                <ManageLayout>
                  <BenefitShow />
                </ManageLayout>
              </Route>
              <Route path="/benefits/create">
                <ManageLayout>
                  <BenefitForm />
                </ManageLayout>
              </Route>
              <Route path="benefits/:benefit_id/update">
                <ManageLayout>
                  <BenefitForm />
                </ManageLayout>
              </Route>
              <Route path="/discounts">
                <ManageLayout>
                  <DiscountIndex />
                </ManageLayout>
              </Route>
              <Route path="/discounts/:id">
                <ManageLayout>
                  <DiscountShow />
                </ManageLayout>
              </Route>
              <Route path="/discounts/create">
                <ManageLayout>
                  <DiscountForm />
                </ManageLayout>
              </Route>
              <Route path="/companies">
                <ManageLayout>
                  <CompanyIndex />
                </ManageLayout>
              </Route>
              <Route path="/companies/:company_id">
                <ManageLayout>
                  <CompanyShow />
                </ManageLayout>
              </Route>
              <Route path="/companies/create">
                <ManageLayout>
                  <CompanyForm />
                </ManageLayout>
              </Route>
              <Route path="/companies/:company_id/update">
                <ManageLayout>
                  <CompanyForm />
                </ManageLayout>
              </Route>
              <Route path="/translations">
                <ManageLayout>
                  <TranslationIndex />
                </ManageLayout>
              </Route>
              <Route path="/translations/create">
                <ManageLayout>
                  <TranslationForm />
                </ManageLayout>
              </Route>
              <Route path="/translations/:translation_id/update">
                <ManageLayout>
                  <TranslationForm />
                </ManageLayout>
              </Route>
              <Route path="/reports/internal-dashboard">
                <ManageLayout>
                  <DashboardReportIndex />
                </ManageLayout>
              </Route>
              <Route path="/reports/internal-dashboard/create">
                <ManageLayout>
                  <DashboardReportForm />
                </ManageLayout>
              </Route>
              <Route path="/reports/internal-dashboard/:report_id/update">
                <ManageLayout>
                  <DashboardReportForm />
                </ManageLayout>
              </Route>
              <Route path="/reports/saas">
                <ManageLayout>
                  <DashboardSaasIndex />
                </ManageLayout>
              </Route>
              <Route path="/settings">
                <ManageLayout>
                  <SettingIndex />
                </ManageLayout>
              </Route>
              <Route path="/users/customers">
                <ManageLayout>
                  <FlycrewPlanSubscriptionIndex />
                </ManageLayout>
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </>
          ) : (
            <Route path="/">
              <Route path="*" element={<Login />} />
              <Route path="/" element={<Login />} />
              <Route
                path="/login/:tokenMagicLink"
                element={<LoginVerification />}
              />
            </Route>
          )}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
