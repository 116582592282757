import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Chip from '@material-ui/core/Chip';
import { Toolbar, Typography, Drawer, Divider, List } from '@material-ui/core';
import ManageNavigation from '../manage/ManageNavigation';
import { SessionContext } from '../../context/SessionContext';
import { HeadContext } from '../../context/HeadContext';
import { SESSION_TOKEN, DEV } from '../../constants';
import manageStyles from '../../styles/manageStyles';

const ManageLayout = ({ children }) => {
  const classes = manageStyles();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { session, setSession } = useContext(SessionContext);
  const { head } = useContext(HeadContext);
  const client = useApolloClient();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ zIndex: 1 }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            endge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {head.title}
          </Typography>
          {
            DEV ? (
              <Chip title="Entorno de desarrollo" label="DEV" style={{ backgroundColor: '#00e676', color: 'white', marginRight: '12px' }} size="small" />
              ) : ('')
          }
          <span>{session.email}</span>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => {
              localStorage.removeItem(SESSION_TOKEN, '');
              client.resetStore();
              setSession(null);
              navigate('/');
            }}
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ManageNavigation handleDrawerClose={handleDrawerClose} />
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {children}
      </main>
    </div>
  );
};

export default ManageLayout;
