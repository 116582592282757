import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CategoryIcon from '@material-ui/icons/Category';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BusinessIcon from '@material-ui/icons/Business';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ForumIcon from '@material-ui/icons/Forum';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import TranslateIcon from '@material-ui/icons/Translate';
import SettingsIcon from '@material-ui/icons/Settings';

import Collapse from '@material-ui/core/Collapse';
import manageStyles from '../../styles/manageStyles';

const ManageNavigation = ({ handleDrawerClose }) => {
  const navigate = useNavigate();
  const classes = manageStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const [openVariable, setOpenVariables] = useState({
    users: false,
    sales: false,
    activities: false,
    pass: false,
    benefits: false,
    reports: false,
    communities: false,
  });

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  const handleVariable = (value) => {
    setOpenVariables({ ...open, [value]: !openVariable[value] });
  };

  const listItemClick = (event, index, path) => {
    handleListItemClick(event, index);
    navigate(path);
    handleDrawerClose();
  };

  return (
    <div>
      <ListItem
        button
        selected={selectedIndex === 0}
        onClick={(event) => listItemClick(event, 0, '/')}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      <ListItem button onClick={() => handleVariable('users')}>
        <ListItemIcon>
          <PeopleAltIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
        {openVariable.users ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openVariable.users} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.manageNested}
            selected={selectedIndex === 3}
            onClick={(event) => {
              handleListItemClick(event, 3);
              navigate('/users');
              handleDrawerClose();
              handleVariable('users');
            }}
          >
            <ListItemIcon>
              <ChevronRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="All" />
          </ListItem>
          <ListItem
            button
            className={classes.manageNested}
            onClick={() => {
              navigate('/users/revenues');
              handleDrawerClose();
              handleVariable('users');
            }}
          >
            <ListItemIcon>
              <ChevronRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Revenues" />
          </ListItem>
          <ListItem
            button
            className={classes.manageNested}
            onClick={() => {
              navigate('/users?with-errors=true');
              handleDrawerClose();
              handleVariable('users');
            }}
          >
            <ListItemIcon>
              <ChevronRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="With errors" />
          </ListItem>

          <ListItem
            button
            className={classes.manageNested}
            onClick={() => {
              navigate('/users?sale-problems=true');
              handleDrawerClose();
              handleVariable('users');
            }}
          >
            <ListItemIcon>
              <ChevronRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Sale Problems" />
          </ListItem>

          <ListItem
            button
            className={classes.manageNested}
            onClick={() => {
              navigate('/users/customers');
              handleDrawerClose();
              handleVariable('users');
            }}
          >
            <ListItemIcon>
              <ChevronRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Customers" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button onClick={() => handleVariable('activities')}>
        <ListItemIcon>
          <EventAvailableIcon />
        </ListItemIcon>
        <ListItemText primary="Activities" />
        {openVariable.activities ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openVariable.activities} timeout="auto" unmountOnExit>
        <ListItem
          button
          className={classes.manageNested}
          onClick={() => {
            navigate('/activities');
            handleDrawerClose();
            handleVariable('activities');
          }}
        >
          <ListItemIcon>
            <ChevronRightIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="All" />
        </ListItem>

        <ListItem
          button
          className={classes.manageNested}
          onClick={() => {
            navigate('/activities/bookings');
            handleDrawerClose();
            handleVariable('activities');
          }}
        >
          <ListItemIcon>
            <ChevronRightIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Bookings" />
        </ListItem>
      </Collapse>

      <ListItem button onClick={() => handleVariable('sales')}>
        <ListItemIcon>
          <LocalAtmIcon />
        </ListItemIcon>
        <ListItemText primary="Sales" />
        {openVariable.sales ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openVariable.sales} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.manageNested}
            selected={selectedIndex === 3}
            onClick={(event) => {
              handleListItemClick(event, 3);
              navigate('/sales');
              handleDrawerClose();
              handleVariable('sales');
            }}
          >
            <ListItemIcon>
              <ChevronRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="All" />
          </ListItem>
          <ListItem
            button
            className={classes.manageNested}
            selected={selectedIndex === 3}
            onClick={(event) => {
              handleListItemClick(event, 3);
              navigate('/sales/pending');
              handleDrawerClose();
              handleVariable('sales');
            }}
          >
            <ListItemIcon>
              <ChevronRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Pending" />
          </ListItem>
          <ListItem
            button
            className={classes.manageNested}
            selected={selectedIndex === 3}
            onClick={(event) => {
              handleListItemClick(event, 3);
              navigate('/sales/extras');
              handleDrawerClose();
              handleVariable('sales');
            }}
          >
            <ListItemIcon>
              <ChevronRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Extras" />
          </ListItem>
          <ListItem
            button
            className={classes.manageNested}
            selected={selectedIndex === 3}
            onClick={(event) => {
              handleListItemClick(event, 3);
              navigate('/sales/transfers');
              handleDrawerClose();
              handleVariable('sales');
            }}
          >
            <ListItemIcon>
              <ChevronRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Transfers" />
          </ListItem>

          <ListItem
            button
            className={classes.manageNested}
            selected={selectedIndex === 3}
            onClick={(event) => {
              handleListItemClick(event, 3);
              navigate('/sales/paysheets');
              handleDrawerClose();
              handleVariable('sales');
            }}
          >
            <ListItemIcon>
              <ChevronRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Paysheets" />
          </ListItem>
        </List>
      </Collapse>

      <ListItem
        button
        selected={selectedIndex === 4}
        onClick={(event) => listItemClick(event, 4, '/categories')}
      >
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Categories" />
      </ListItem>

      <ListItem
        button
        onClick={(event) => listItemClick(event, 5, '/discounts')}
      >
        <ListItemIcon>
          <LocalAtmIcon />
        </ListItemIcon>
        <ListItemText primary="Discounts" />
      </ListItem>
      <ListItem button onClick={() => handleVariable('reports')}>
        <ListItemIcon>
          <AssessmentIcon />
        </ListItemIcon>

        <ListItemText primary="Reports" />
        {openVariable.reports ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openVariable.reports} timeout="auto" unmountOnExit>
        <ListItem
          button
          className={classes.manageNested}
          onClick={() => {
            navigate('/reports/internal-dashboard');
            handleDrawerClose();
          }}
        >
          <ListItemIcon>
            <ChevronRightIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Internal Dashboard" />
        </ListItem>
        <ListItem
          button
          className={classes.manageNested}
          onClick={() => {
            navigate('/reports/saas');
            handleDrawerClose();
          }}
        >
          <ListItemIcon>
            <ChevronRightIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="SaaS Report" />
        </ListItem>
      </Collapse>

      <ListItem
        button
        onClick={(event) => listItemClick(event, 6, '/companies')}
      >
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Companies" />
      </ListItem>

      <ListItem button onClick={() => handleVariable('benefits')}
      >
        <ListItemIcon>
          <CardGiftcardIcon />
        </ListItemIcon>

        <ListItemText primary="Benefits" />
        {openVariable.benefits ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openVariable.benefits} timeout="auto" unmountOnExit>
        <ListItem
          button
          className={classes.manageNested}
          onClick={() => {
            navigate('/benefits');
            handleDrawerClose();
            handleVariable('pass');
          }}
        >
          <ListItemIcon>
            <ChevronRightIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="All" />
        </ListItem>

        <ListItem
          button
          className={classes.manageNested}
          onClick={() => {
            navigate('/benefits/categories');
            handleDrawerClose();
            handleVariable('pass');
          }}
        >
          <ListItemIcon>
            <ChevronRightIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>

      </Collapse>

      <ListItem button onClick={() => handleVariable('pass')}>
        <ListItemIcon>
          <EventAvailableIcon />
        </ListItemIcon>
        <ListItemText primary="Pass" />
        {openVariable.pass ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openVariable.pass} timeout="auto" unmountOnExit>
        <ListItem
          button
          className={classes.manageNested}
          onClick={() => {
            navigate('/pass/categories');
            handleDrawerClose();
            handleVariable('pass');
          }}
        >
          <ListItemIcon>
            <ChevronRightIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>
      </Collapse>

      <ListItem
        button
        onClick={(event) => listItemClick(event, 10, '/plans')}
      >
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Plans" />
      </ListItem>

      <ListItem
        button
        selected={selectedIndex === 4}
        onClick={(event) => listItemClick(event, 4, '/translations')}
      >
        <ListItemIcon>
          <TranslateIcon />
        </ListItemIcon>
        <ListItemText primary="Translations" />
      </ListItem>

      <ListItem
        button
        selected={selectedIndex === 4}
        onClick={(event) => listItemClick(event, 4, '/settings')}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </div>
  );
};

export default ManageNavigation;
