import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ToastProvider } from 'react-toast-notifications';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { GRAPHQL_API_URI, SESSION_TOKEN } from './constants';
import { SessionProvider } from './context/SessionContext';
import { HeadProvider } from './context/HeadContext';
import LayoutWrapper from './components/layout/LayoutWrapper';
import App from './App';

const httpLink = createHttpLink({
  uri: GRAPHQL_API_URI,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(SESSION_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          feed: offsetLimitPagination(['types']),
        },
      },
    },
  }),
  fetchOptions: {
    credentials: 'include',
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <LayoutWrapper>
      <SessionProvider>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={5000}
          placement="top-center"
        >
          <HeadProvider>
            <App />
          </HeadProvider>
        </ToastProvider>
      </SessionProvider>
    </LayoutWrapper>
  </ApolloProvider>,
  document.getElementById('root'),
);
